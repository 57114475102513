<template>
  <div>
    <e-sidebar
      :title="edit ? $t('Editar Tabela de Preço') : $t('Nova Tabela de Preço')"
      :show="showSidebar"
      :fetching="fetching"
      :saving="saving"
      width="500px"
      @save="savePriceTable"
      @hidden="cleanSidebar"
    >
      <template #content>
        <div>
          <FormulateForm
            ref="form"
            name="form"
            @submit="savePriceTable"
          >
            <b-row>
              <b-col md="12">
                <FormulateInput
                  id="price-table-name"
                  v-model="localPriceTable.name"
                  :is-edit="false"
                  type="text"
                  class="required"
                  validation="required"
                  :label="$t('Nome')"
                />
              </b-col>
              <b-col md="12">
                <FormulateInput
                  id="price-table-retail-discount"
                  v-model="localPriceTable.retailDiscount"
                  :is-edit="false"
                  type="text-percetage"
                  currency-symbol-position="suffix"
                  currency="%"
                  :precision="4"
                  :label="$t('Desconto')"
                />
              </b-col>
            </b-row>
          </FormulateForm>
        </div>
      </template>
    </e-sidebar>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { ESidebar } from '@/views/components'
import { uploader } from '@/mixins'

const defaultPriceTable = () => ({
  id: null,
  name: '',
  retailDiscount: 0,
})

export default {
  components: {
    BRow,
    BCol,
    ESidebar,
  },

  mixins: [uploader],

  props: {
    priceTable: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      priceTableId: null,
      showSidebar: false,
      fetching: false,
      edit: false,
      saving: false,
      localPriceTable: defaultPriceTable(),
    }
  },

  watch: {
    priceTable(val) {
      this.localPriceTable = {
        ...val,
        retailDiscount: val.retailDiscount,
      }
    },
    async priceTableId(val) {
      if (val) {
        try {
          this.fetching = true
          const { data: localPriceTable } = await this.$http.get(
            `/api/price-tables/${this.priceTableId}`
          )
          this.localPriceTable = {
            ...localPriceTable,
            retailDiscount: localPriceTable.retailDiscount,
          }
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      } else {
        // this.priceTable = defaultPriceTable()
        this.$emit('clean-price-table', defaultPriceTable())
      }
    },
  },

  methods: {
    showCreate() {
      this.localPriceTable = defaultPriceTable()
      this.imageLocal = []
      this.edit = false
      this.showSidebar = true
    },

    async show({ id, item } = {}) {
      if (!id && !item?.id) {
        this.showCreate()
      } else {
        if (id) this.priceTableId = id
        if (item) this.priceTable = item
        this.edit = true
        this.showSidebar = true
      }
    },

    async savePriceTable() {
      this.$refs.form.showErrors()
      if (this.$refs.form.isValid) {
        try {
          this.saving = true
          const body = {
            id: this.localPriceTable.id,
            name: this.localPriceTable.name,
            retailDiscount: this.localPriceTable.retailDiscount,
          }
          const result = await this.$http({
            url: '/api/price-tables',
            method: this.edit ? 'PUT' : 'POST',
            data: body,
          })
          this.showSuccess({
            message: this.$t(`${this.edit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
          })
          this.$emit('save', result.data)
          this.cleanSidebar()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.saving = false
        }
      } else {
        this.showInvalidDataMessage()
      }
    },

    cleanSidebar() {
      this.priceTableId = null
      this.localPriceTable = defaultPriceTable()
      this.edit = false
      this.showSidebar = false
    },
  },
}
</script>

<style></style>
