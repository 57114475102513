var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("e-sidebar", {
        attrs: {
          title: _vm.edit
            ? _vm.$t("Editar Tabela de Preço")
            : _vm.$t("Nova Tabela de Preço"),
          show: _vm.showSidebar,
          fetching: _vm.fetching,
          saving: _vm.saving,
          width: "500px",
        },
        on: { save: _vm.savePriceTable, hidden: _vm.cleanSidebar },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  [
                    _c(
                      "FormulateForm",
                      {
                        ref: "form",
                        attrs: { name: "form" },
                        on: { submit: _vm.savePriceTable },
                      },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c("FormulateInput", {
                                  staticClass: "required",
                                  attrs: {
                                    id: "price-table-name",
                                    "is-edit": false,
                                    type: "text",
                                    validation: "required",
                                    label: _vm.$t("Nome"),
                                  },
                                  model: {
                                    value: _vm.localPriceTable.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.localPriceTable, "name", $$v)
                                    },
                                    expression: "localPriceTable.name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "price-table-retail-discount",
                                    "is-edit": false,
                                    type: "text-percetage",
                                    "currency-symbol-position": "suffix",
                                    currency: "%",
                                    precision: 4,
                                    label: _vm.$t("Desconto"),
                                  },
                                  model: {
                                    value: _vm.localPriceTable.retailDiscount,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.localPriceTable,
                                        "retailDiscount",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "localPriceTable.retailDiscount",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }